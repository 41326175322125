import React from "react";
import { Button, Col, DatePicker, Input, Row, Select, Tooltip } from "antd";
import { startCase, snakeCase } from "lodash";
import { picker } from "../../utils/style.module.less";
import { format } from "date-fns";

export const GsSelect = (props) => {
  const { label = "", aslabel, width = 200, onChange, style = {}, multiple = false } = props;
  return (
    <Col className={picker}>
      <h4 style={{ paddingLeft: "0.25rem" }} className="c3">
        {aslabel || startCase(`${label}`)}
      </h4>
      <Select
        {...props}
        showSearch
        showArrow
        placeholder={`Select ${aslabel || startCase(label)}`}
        style={{ width, ...style }}
        mode={multiple ? "multiple" : false}
        name={snakeCase(label)}
        onChange={(value) => onChange({ [aslabel?.toLowerCase() || snakeCase(label)]: value })}
      />
    </Col>
  );
};

export const GsInput = (props) => {
  const { label = "", aslabel, width = 200, onChange, style = {}, type } = props;
  return (
    <Col className={picker}>
      <h4 style={{ paddingLeft: "0.25rem" }} className="c3">
        {/* {aslabel || startCase(`${label}`)} */}
        {startCase(`${label}`)}
      </h4>
      <Input
        {...props}
        type={type}
        // showSearch
        // showArrow
        name={label}
        placeholder={`${aslabel || startCase(label)}`}
        style={{ width, ...style, transform: "translateX(-10px)" }}
        onChange={onChange}
        // onChange={(e) => onChange({ [aslabel?.toLowerCase() || snakeCase(label)]: e.target.value })}
      />
    </Col>
  );
};

export const GsRangePicker = (props) => {
  const { label = "", disabled } = props;
  const { RangePicker } = DatePicker;
  return (
    <Col className={picker}>
      <h4 style={{ paddingLeft: "0.5rem" }} className="c3">
        {startCase(`${label}`)}
      </h4>
      <RangePicker
        disabled={disabled}
        format={"YYYY-MM-DD"}
        style={{ marginRight: "0.25rem" }}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        clearIcon={false}
      />
    </Col>
  );
};

export const GsDatePicker = (props) => {
  const { label = "", onChange, disabled } = props;
  return (
    <Col className={picker}>
      <h4 style={{ paddingLeft: "0.25rem" }} className="c3">
        {startCase(`${label}`)}
      </h4>
      <DatePicker
        disabled={disabled}
        format={"YYYY-MM-DD"}
        style={{ marginRight: "1rem" }}
        onChange={(value) => onChange({ [snakeCase(label)]: format(new Date(value), "yyyy-MM-dd") })}
      />
    </Col>
  );
};

export const GsButton = (props) => {
  const { label = "Submit", style = {}, p, type, offset = "0.75rem", width, tooltip, disabled } = props;
  return (
    <Row
      className={picker}
      sx={{
        paddingTop: offset,
        margin: "auto",
        width: width,
      }}
    >
      <Tooltip title={tooltip}>
        <Button {...props} disabled={disabled} style={{ height: "100%", ...style }}>
          <b className={type !== "primary" ? "c1" : ""} style={{ padding: p || "0.5rem" }}>
            {label}
          </b>
        </Button>
      </Tooltip>
    </Row>
  );
};

export default GsSelect;
